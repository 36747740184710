<template>
  <section>
    <e-filters
      ref="filters"
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="2">
            <FormulateInput
              id="purchase_order_id"
              v-model="filters.id"
              type="number"
              :label="$t('Id do pedido')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="invoice_number"
              v-model="filters.invoiceNumber"
              type="number"
              :label="$t('Número da nota')"
            />
          </b-col>
          <b-col md="3">
            <e-store-combo
              id="store-id"
              v-model="filters.storeId"
              :required="false"
            />
          </b-col>

          <b-col md="3">
            <e-person-search
              v-model="filters.supplierId"
              :label="$t('Pesquisar fornecedor')"
              is-supplier
              :only-active="false"
            />
          </b-col>

          <b-col md="2">
            <FormulateInput
              id="status"
              v-model="filters.status"
              type="vue-select"
              :label="$t('Status')"
              :options="[
                { label: 'Todos', value: '' },
                ...mxPurchaseOrderStatusOptions(),
              ]"
              :clearable="false"
            />
          </b-col>

          <b-col md="4">
            <e-search-sku
              id="product-name"
              v-model="filters.skuId"
              :required="false"
            />
          </b-col>

          <b-col md="4">
            <FormulateInput
              id="invoice_date"
              v-model="filters.invoiceDate"
              type="date-range-picker"
              :label="$t('Período da Nota')"
              :time-picker="false"
            />
          </b-col>

          <b-col md="4">
            <FormulateInput
              id="created_date"
              v-model="filters.createdDate"
              type="date-range-picker"
              :label="$t('Período do recebimento')"
              :time-picker="false"
            />
          </b-col>
        </b-row>
      </div>

      <template #after_buttons>
        <e-button
          class="ml-1"
          :busy="fetching"
          :text="$t('Baixar Relatório')"
          variant="primary"
          icon="download"
          @click="downloadReport"
        />
      </template>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="getData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <b-table
        ref="purchaseOrderTable"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('PURCHASE_ORDER.NAME'))"
        :fields="fields"
        :items="orderPuchases"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(action)="row">
          <e-grid-actions
            :show-update="showUpdate(row.item)"
            :show-delete="showDelete(row.item)"
            :buttons="gridButtons(row.item)"
            @update="showUpdatePage(row.item)"
            @delete="showDeletePurchaseOrder(row.item)"
            @details="showDetailsPage(row.item)"
            @return-items="showModalReturnItems(row)"
            @reverse="reversePurchaseOrder(row.item)"
          />
        </template>

        <template #cell(status)="row">
          <e-status-badge
            :status="row.item.status"
            :options="mxPurchaseOrderStatusOptions()"
          />
        </template>
      </b-table>
      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="order-purchase-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <modal-return-invoice-items
      ref="modalReturnInvoiceItems"
      :busy="false"
      @reverse-purchase-order="reversePurchaseOrder"
    />

    <modal-delete-purchase-order
      ref="modalDelete"
      @after-confirm="getData"
    />

    <!-- v-if="$can('Create', 'Product')" -->
    <fab
      :main-tooltip="$t('Novo Pedido')"
      main-icon="keyboard_command_key"
      :actions="actions"
      :fixed-tooltip="true"
      @create-with-import="showCreateByImportPage"
      @create-manual="showCreateManualPage"
    />
  </section>
</template>

<script>
import moment from 'moment'
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { EPageSizeSelector, EPaginationSummary, EFilters, EGridActions, EStoreCombo, ESearchSku, EPersonSearch, EButton } from '@/views/components'
import fab from '@/views/components/FAB.vue'
import { downloader, purchaseOrderDomains } from '@/mixins'
import { mapActions, mapGetters, mapState } from 'vuex'
import EStatusBadge from '@/views/components/EStatusBadge.vue'
import ModalReturnInvoiceItems from './components/ModalReturnInvoiceItems.vue'
import ModalDeletePurchaseOrder from './components/ModalDeletePurchaseOrder.vue'

export default {
  name: 'PurchaseOrderList',
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    fab,
    EFilters,
    EGridActions,
    EStoreCombo,
    ModalReturnInvoiceItems,
    ESearchSku,
    EPersonSearch,
    EButton,
    ModalDeletePurchaseOrder,
    EStatusBadge,
  },

  mixins: [downloader, purchaseOrderDomains],

  data() {
    return {
      fetching: false,
      suppliers: [],
    }
  },

  computed: {
    ...mapState('pages/purchase/purchaseOrder', {
      orderPuchases: 'purchaseOrders',
      paging: 'paging',
      sorting: 'sorting',
      filters: 'filters',
    }),
    ...mapGetters('app', ['storeOptions']),

    showUpdate() {
      return purchaseOrder => !this.mxIsPurchaseOrderReadOnly(purchaseOrder)
    },
    showDelete() {
      return purchaseOrder => [this.mxPurchaseOrderStatusEnum.DRAFT].indexOf(purchaseOrder.status) >= 0
    },

    gridButtons() {
      return purchaseOrder => {
        if (!this.mxIsPurchaseOrderReadOnly(purchaseOrder)) return []

        return [
          {
            icon: 'search',
            event: 'details',
            variant: 'outline-primary',
            title: this.$t('Ver detalhes'),
          },
          {
            icon: 'arrow-return-left',
            event: 'return-items',
            variant: 'outline-primary',
            title: this.$t('Devolução da compra'),
            hide: purchaseOrder.status !== 'Completed' || purchaseOrder.status === 'Submitted',
          },
          {
            icon: 'x-circle',
            event: 'reverse',
            variant: 'danger',
            title: this.$t('Estornar Pedido'),
            hide: purchaseOrder.status !== 'Completed' || purchaseOrder.status === 'Submitted',
          },
        ].filter(btn => !btn.hide)
      }
    },
    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Id'),
          key: 'id',
          tdClass: 'text-right',
          thStyle: { width: '80px' },
          sortable: true,
        },
        {
          label: this.$t('Status'),
          key: 'status',
          class: 'text-center',
          thStyle: { width: '170px' },
          sortable: true,
        },
        {
          label: this.$t('Tipo'),
          key: 'type',
          class: 'text-center',
          thStyle: { width: '90px' },
          sortable: true,
        },
        {
          label: this.$t('Loja'),
          key: 'store',
          formatter: value => this.$options.filters.storeName(value),
        },
        {
          label: this.$t('Fornecedor'),
          key: 'supplier.name',
          sortable: true,
        },
        {
          label: this.$t('Data da Nota'),
          key: 'invoiceDate',
          class: 'text-center',
          formatter: value => this.$options.filters.date(value),
          thStyle: { width: '150px' },
          sortable: true,
        },
        {
          label: this.$t('Recebimento'),
          key: 'createdDate',
          class: 'text-center',
          formatter: value => this.$options.filters.date(value),
          thStyle: { width: '150px' },
          sortable: true,
        },
        {
          label: this.$t('Valor'),
          key: 'totalValue',
          tdClass: 'text-right',
          formatter: value => this.$options.filters.currency(value),
          thStyle: { width: '160px' },
          sortable: true,
        },
      ]
    },

    actions() {
      return [
        {
          name: 'create-with-import',
          icon: 'note_add',
          color: 'green',
          tooltip: this.$t('Importando NF'),
        },
        {
          name: 'create-manual',
          icon: 'add',
          color: 'green',
          tooltip: this.$t('Manual'),
        },
      ]
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/purchase/purchaseOrder/purchaseOrderMaintain', {
      cleanPurchaseOrderMaintain: 'cleanState',
      stReversePurchaseOrder: 'reversePurchaseOrder',
    }),
    ...mapActions('pages/purchase/purchaseOrder/purchaseOrderManualMaintain', {
      cleanPurchaseOrderManualMaintain: 'cleanState',
    }),
    ...mapActions('pages/purchase/purchaseOrder', [
      'fetchOrderPurchase',
      'setFilter',
      'setCurrentPage',
      'setPageSize',
      'resetFilters',
    ]),

    async getData(exportData) {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchOrderPurchase(exportData)
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    async downloadReport() {
      try {
        if (this.$refs.filters.isValid()) {
          this.fetching = true
          const data = await this.fetchOrderPurchase(true)

          if (data) {
            this.forceFileDownload(data, `Pedidos_de_Compra_${moment().format()}.xlsx`)
          } else {
            this.showError({ message: this.$t('Não foi possível recuperar os dados do relatório') })
          }
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },

    async reversePurchaseOrder(purchaseOrder, { confirmTitle } = {}) {
      if (
        await this.confirm({
          title: confirmTitle,
          text: `Confirma o estorno do pedido #${purchaseOrder.id}? As movimentações de estoque, preço e contas a pagar serão revertidas e o pedido voltará ao status de "Em preenchimento".`,
        })
      ) {
        try {
          this.fetching = true
          await this.stReversePurchaseOrder(purchaseOrder.id)
        } catch (error) {
          this.showError({ error })
        } finally {
          this.fetching = false
        }
        this.getData()
      }
    },

    async showDeletePurchaseOrder(purchaseOrder) {
      this.$refs.modalDelete.showModal(purchaseOrder)
    },

    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.getData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    showCreateByImportPage() {
      this.cleanPurchaseOrderMaintain()
      this.$router.push({ name: 'purchase-order-add' })
    },

    showCreateManualPage() {
      this.cleanPurchaseOrderManualMaintain()
      this.$router.push({ name: 'purchase-order-add-manual' })
    },

    async showUpdatePage(purchaseOrder) {
      const { id, type } = purchaseOrder
      const { XML, SEFAZ } = purchaseOrderDomains.data().PurchaseOrderTypeEnum
      if (type === XML || type === SEFAZ) {
        this.$router.push({
          name: 'purchase-order-maintain',
          params: { id },
        })
      } else {
        this.$router.push({
          name: 'purchase-order-manual-details',
          params: { id },
        })
      }
    },

    async showDetailsPage(purchaseOrder) {
      const { id, type } = purchaseOrder
      const { XML, SEFAZ } = purchaseOrderDomains.data().PurchaseOrderTypeEnum
      if (type === XML || type === SEFAZ) {
        this.$router.push({
          name: 'purchase-order-details',
          params: { id },
        })
      } else {
        this.$router.push({
          name: 'purchase-order-manual-details',
          params: { id },
        })
      }
    },

    async showModalReturnItems(row) {
      if (!this.$can('PurchaseReturn', 'PurchaseOrder')) {
        this.showWarning({ message: this.$t('PURCHASE_ORDER.VALIDATION_ERROR.USER_CAN_NOT_RETURN_PURCHASE_NO_PERMISSION') })
        return
      }
      const purchaseOrderId = row.item.id
      try {
        await this.$refs.modalReturnInvoiceItems.show({ purchaseOrderId })
      } catch (error) {
        this.showError({ error })
      }
    },
  },
}
</script>

<style></style>
