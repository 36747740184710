var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "e-filters",
        {
          ref: "filters",
          attrs: { searching: _vm.fetching },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
          scopedSlots: _vm._u([
            {
              key: "after_buttons",
              fn: function () {
                return [
                  _c("e-button", {
                    staticClass: "ml-1",
                    attrs: {
                      busy: _vm.fetching,
                      text: _vm.$t("Baixar Relatório"),
                      variant: "primary",
                      icon: "download",
                    },
                    on: { click: _vm.downloadReport },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "purchase_order_id",
                          type: "number",
                          label: _vm.$t("Id do pedido"),
                        },
                        model: {
                          value: _vm.filters.id,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "id", $$v)
                          },
                          expression: "filters.id",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "invoice_number",
                          type: "number",
                          label: _vm.$t("Número da nota"),
                        },
                        model: {
                          value: _vm.filters.invoiceNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "invoiceNumber", $$v)
                          },
                          expression: "filters.invoiceNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("e-store-combo", {
                        attrs: { id: "store-id", required: false },
                        model: {
                          value: _vm.filters.storeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "storeId", $$v)
                          },
                          expression: "filters.storeId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("e-person-search", {
                        attrs: {
                          label: _vm.$t("Pesquisar fornecedor"),
                          "is-supplier": "",
                          "only-active": false,
                        },
                        model: {
                          value: _vm.filters.supplierId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "supplierId", $$v)
                          },
                          expression: "filters.supplierId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "status",
                          type: "vue-select",
                          label: _vm.$t("Status"),
                          options: [{ label: "Todos", value: "" }].concat(
                            _vm.mxPurchaseOrderStatusOptions()
                          ),
                          clearable: false,
                        },
                        model: {
                          value: _vm.filters.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "status", $$v)
                          },
                          expression: "filters.status",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("e-search-sku", {
                        attrs: { id: "product-name", required: false },
                        model: {
                          value: _vm.filters.skuId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "skuId", $$v)
                          },
                          expression: "filters.skuId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "invoice_date",
                          type: "date-range-picker",
                          label: _vm.$t("Período da Nota"),
                          "time-picker": false,
                        },
                        model: {
                          value: _vm.filters.invoiceDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "invoiceDate", $$v)
                          },
                          expression: "filters.invoiceDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "created_date",
                          type: "date-range-picker",
                          label: _vm.$t("Período do recebimento"),
                          "time-picker": false,
                        },
                        model: {
                          value: _vm.filters.createdDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "createdDate", $$v)
                          },
                          expression: "filters.createdDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.getData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            ref: "purchaseOrderTable",
            staticClass: "bordered",
            attrs: {
              "show-empty": "",
              responsive: "",
              striped: "",
              "empty-text": _vm.getEmptyTableMessage(
                _vm.$tc("PURCHASE_ORDER.NAME")
              ),
              fields: _vm.fields,
              items: _vm.orderPuchases,
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "sort-changed": _vm.getData,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(action)",
                fn: function (row) {
                  return [
                    _c("e-grid-actions", {
                      attrs: {
                        "show-update": _vm.showUpdate(row.item),
                        "show-delete": _vm.showDelete(row.item),
                        buttons: _vm.gridButtons(row.item),
                      },
                      on: {
                        update: function ($event) {
                          return _vm.showUpdatePage(row.item)
                        },
                        delete: function ($event) {
                          return _vm.showDeletePurchaseOrder(row.item)
                        },
                        details: function ($event) {
                          return _vm.showDetailsPage(row.item)
                        },
                        "return-items": function ($event) {
                          return _vm.showModalReturnItems(row)
                        },
                        reverse: function ($event) {
                          return _vm.reversePurchaseOrder(row.item)
                        },
                      },
                    }),
                  ]
                },
              },
              {
                key: "cell(status)",
                fn: function (row) {
                  return [
                    _c("e-status-badge", {
                      attrs: {
                        status: row.item.status,
                        options: _vm.mxPurchaseOrderStatusOptions(),
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "order-purchase-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("modal-return-invoice-items", {
        ref: "modalReturnInvoiceItems",
        attrs: { busy: false },
        on: { "reverse-purchase-order": _vm.reversePurchaseOrder },
      }),
      _c("modal-delete-purchase-order", {
        ref: "modalDelete",
        on: { "after-confirm": _vm.getData },
      }),
      _c("fab", {
        attrs: {
          "main-tooltip": _vm.$t("Novo Pedido"),
          "main-icon": "keyboard_command_key",
          actions: _vm.actions,
          "fixed-tooltip": true,
        },
        on: {
          "create-with-import": _vm.showCreateByImportPage,
          "create-manual": _vm.showCreateManualPage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }